<template>
  <div :class="['system-alert', { 'system-alert__fixed': isFixed }]">
    <div class="system-alert__left">
      <img
        alt="info"
        src="https://files.lxp.academy.who.int/didactica/assets/images/learning-space/main/information.svg"
      />
      <i18next
        path="system_alert.content"
        tag="p"
        class="system-alert__content"
      >
        <span>{{
          getSystemAlert.start_date | formatDate("DD/MM/YYYY HH:mm")
        }}</span>
        <span v-if="getSystemAlert.end_date">
          {{ getSystemAlert.end_date | formatDate("DD/MM/YYYY HH:mm") }}</span
        >
        <a href="mailto:academytech@who.int" target="_blank"
          >academytech@who.int</a
        >
      </i18next>
    </div>
    <img
      src="https://files.lxp.academy.who.int/didactica/assets/images/modal/close.svg"
      alt="close"
      class="system-alert__close"
      @click="onClose"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["getSystemAlert"])
  },
  methods: {
    onClose() {
      this.$store.commit("SET_SYSTEM_ALERT_STATUS", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.system-alert {
  background: $brand-secondary2-400;
  padding: 8px 16px 8px 24px;
  @include horizontal-space-between;
  &.system-alert__fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
  }
  .system-alert__left {
    @include flex-horizontal-center;
    .system-alert__content {
      @include label-large($brand-neutral-700, 500);
      margin: 0 0 0 8px;
      text-align: left;
    }
  }
  .system-alert__close {
    cursor: pointer;
  }
}
[dir="rtl"] {
  .system-alert {
    .system-alert__left {
      .system-alert__content {
        text-align: right;
        margin: 0 8px 0 0;
      }
    }
  }
}
</style>
